.step-progress {
    margin: 3rem 0;
   }
  .multi-steps {
      display: table;
      table-layout: fixed;
      width: 100%;
      position: sticky;
      top: 110px;
      z-index: 5;
      background: #ffffff;
      padding-top: 45px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
  }

  .multi-steps-vendor{top: 0px;}

   .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #808080;
    font-size: 0.875rem;
   }
   .multi-steps > li.is-active:before {
    background-color: #fff;
   }
   .multi-steps > li.is-active:before {
    background-image: url(../img/circle-solid-green.svg) !important;
   }
   .multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
    content: '';
    font-weight: 700;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    font-size: 12px;
    background-image: url(../img/circle-solid.svg);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
   }
   .multi-steps > li span {
    display: block;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -22px;
    color: #fff;
    background: #41a70f;
    text-align: center;
    width: 44px;
    font-weight: 700;
    padding: 4px 0;
    border-radius: 6px;
   }
   li.is-active {
    color: #41a70f;
   }
   .multi-steps > li span:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #41a70f;
    bottom: -7px;
    left: 50%;
    margin-left: -8px;
   }
   .multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
    background-color: #bebebe;
   }
   .multi-steps > li:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #41a70f;
    position: absolute;
    top: 13px;
    left: 50%;
    z-index: -1;
   }
   .multi-steps > li.is-active ~ li:before {
    background-color: #fff;
    border-color: #fff;
   }
   .multi-steps > li:before {
    display: block;
    margin: 0 auto 4px;
    background-color: var(--white-text);
    width: 28px;
    height: 28px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
   }
   .multi-steps > li:last-child::after {
    display: none;
   }
   h3.main-title-step {
    margin-bottom: 30px;
    font-size: 1.8rem;
   }
   .customized-loan-offer.is-active .offer-header {
    background: var(--primary) !important;
    margin-bottom: 5px;
    color: #fff;
    position: relative;
    padding: 15px 50px;
    display: block !important;
   }
   .is-active .mid-panel {
    display: block !important;
   }
   .customized-loan-offer.is-active .offer-header:before {
    background: #ff9500;
    border: 6px solid #fff;
   }
   .offer-header:before {
    width: 21px;
    height: 21px;
    border-radius: 100%;
    border: 1px solid #fff;
   }
   .offer-header:after, .offer-header:before {
    position: absolute;
    content: '';
    left: 15px;
    top: 50%;
    margin-top: -11px;
   }
   .customized-loan-offer .title-sm {
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0;
   }
   .customized-loan-offer.is-active .offer-header .edit {
    display: none;
    color: #5e707c;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
   }
   .offer-header .edit {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 15px;
    display: block;
   }
   button.edit.show_step {
    color: white !important;
    cursor: pointer;
    border-style: none;
    background: transparent;
   }
   .loan-form .form-control {
    background-color: transparent;
    padding-left: 25px;
   }
   .loan-form .input-group-prepend.otp-sec {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    background: #ec232d;
    padding: 0;
    height: 100%;
   }
   .form-control {
    box-shadow: none;
    border-radius: 0;
    height: calc(2.25rem + 10px);
   }
   .loan-form .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 0.875rem;
   }
   .loan-form .input-group-prepend.otp-sec:hover a {
    color: var(--white);
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205);
    border-color: transparent;
   }
   .btn-primary.resend-otp {
    color: var(--white);
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205);
    border-color: transparent;
    border: 0;
    border-radius: 0;
    font-weight: normal;
    font-size: 13px !important;
    line-height: inherit;
    height: 46px;
    padding: 6px 12px !important;
   }
   .loan-form .form-group {
    margin-bottom: 1.5rem;
   }
   .loan-form label {
      font-weight: 400;
      font-size: 15px;
      min-height: 42px;
      display: flex;
      align-items: end;
  }
   .hide-label {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
   }
   .loan-form .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: var(--font-size-2);
   }
   .loan-form .input-group-addon, .loan-form .input-group-prepend {
    position: absolute;
    right: 13px;
    top: 14px;
    z-index: 5;
   }
   .loan-form .input-group-prepend.otp-sec {
    position: relative;
    right: 0;
    top: 0;
    z-index: 5;
    background: #ec232d;
    padding: 0;
    height: 100%;
   }
   .loan-form .input-group-prepend.otp-sec button {
    display: block;
    width: 100%;
    padding: 0 10px;
    line-height: 46px;
    border: 0;
    color: var(--white);
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205);
    border-color: transparent;
   }
   .loan-form .input-group-prepend.otp-sec button:hover {
    color: var(--white);
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205);
    border-color: transparent;
   }
   .loan-form .input-group-prepend.otp-sec:hover a {
    color: var(--white);
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205);
    border-color: transparent;
   }
   .loan-form .form-group {
    margin-bottom: 1.5rem;
   }
   .loan-form .form-control {
    background-color: transparent;
   }
   .loan-form .btn-radio input[type='radio'] + label {
    border: 1px solid #d3d7dd;
    min-width: 140px;
    text-align: left;
    text-transform: uppercase;
    padding: 5px 10px 5px 40px;
    line-height: 38px;
    margin: 0 15px 15px 0;
    font-size: 13px;
   }
   .loan-form .btn-radio input[type='radio']:checked + label {
    border-color: #3a2d7d;
    background-color: #3a2d7d;
    color: #fff;
   }
   .loan-form .btn-radio input[type='radio'] + label:before {
    border: 1px solid #b4b4be;
    width: 16px;
    height: 16px;
    left: 14px;
    top: 50%;
    border-radius: 100%;
    margin-top: -10px;
   }
   .loan-form .btn-radio input[type='radio'] + label:after {
    content: '';
    font-family: FontAwesome;
    line-height: 16px;
    text-align: center;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 14px;
    background: red;
    border-radius: 100%;
    margin-top: -10px;
    background-image: url(../img/check-main.svg);
    background-size: 66%;
    background-repeat: no-repeat;
    background-position: center;
   }
   .loan-form .img-radio .btn-radio input[type='radio'] + label {
    text-align: left;
    padding: 5px 10px;
   }
   .loan-form .img-radio .btn-radio input[type='radio'] + label::before {
    left: inherit;
    top: 14px;
    border-radius: 100%;
    margin-top: 0;
    right: 10px;
   }
   .loan-form .img-radio .btn-radio input[type='radio'] + label::after {
    top: 14px;
    left: inherit;
    margin-top: 0;
    right: 10px;
   }
   .mid-panel {
    padding: 20px;
    background: #fff;
    color: var(--dark-text);
    display: none;
   }
   .btn-primary {
    border-color: var(--red-background);
    background: var(--red-background);
    color: var(--white-text);
   }
   .offer-footer .btn-primary {
    border-radius: 0;
    font-size: 16px;
    font-weight: normal;
    padding: 6px 12px !important;
    height: 38px;
    display: flex;
    align-items: center;
    color: var(--white) !important;
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205) !important;
    border-color: transparent !important;
    transition-duration: 0.4s !important;
   }
   .offer-footer .btn-primary:hover, .offer-footer .btn-primary:focus, .offer-footer .btn-primary:active, .offer-footer .btn-primary:active, .offer-footer .btn-primary:not(:disabled):not(.disabled):active {
    color: var(--white) !important;
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205) !important;
    border-color: transparent !important;
   }
   .customized-loan-offer .offer-header {
    background: #b9bbbe;
    margin-bottom: 5px;
    color: var(--white-text);
    position: relative;
    padding: 15px 50px;
   }
   .panel-links {
    border-left: 1px solid #d3d8e0;
   }
   .panel-links aside {
    padding: 2rem;
   }
   .panel-links p {
    font-size: var(--font-size-2);
    line-height: normal;
   }
   .panel-links ul {
    margin: 0;
    padding: 0;
    list-style: none;
   }
   .panel-links li, .panel-links p {
    border-bottom: 1px solid #d3d8e0;
    padding: 15px 0;
    margin: 0;
   }
   .panel-links li a {
    color: #8a8898;
   }
   .panel-links p span {
    display: block;
    color: #ec232d;
    font-size: var(--font-size-4);
    margin-top: 4px;
   }
   .panel-links p a {
    color: #ec232d;
    text-decoration: underline;
   }
   .form-group-field {
    margin: 0 !important;
    height: 110px;
   }
   @media (max-width:767px) {
    .loan-form .btn-radio input[type='radio'] + label {
     width: 100%;
     min-width: 100%;
     margin: 0;
    }
    .form-inline.row .select-city {
     flex: 0 0 100%;
    }
    .form-inline.row .select-city .btn-radio {
     width: 100%;
    }
   }
   .active-line::after {
    background-color: #41a70f !important;
   }
   .active-border::before {
    background-color: #fff;
    border-color: #41a710 !important;
   }
   .btn-primary.resend-otp {
    color: var(--white) !important;
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205) !important;
    border-color: transparent !important;
   }
   .btn-primary.resend-otp:hover {
    color: var(--white) !important;
    background-image: linear-gradient(to right bottom, #eb252f, #bc1728, #8e0c20, #620516, #3a0205) !important;
    border-color: transparent !important;
   }
   .mandatory-field {
    display: inline-block;
    background: #fde9ea;
    color: #ec232d;
    border-left: 5px solid #ec232d;
    font-size: var(--font-size-1);
    padding: 0 10px;
    line-height: 24px;
   }
   .show_err {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: #dc3545;
   }
   .show_success {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: #3c9402;
   }
   .active__color {
    background-color: #3a2d7d !important;
   }
   header.offer-header.active__color::before {
    background-image: url(../img/tick.png);
    background-size: cover;
    background-color: #fff;
    border: 0;
   }
   .active__color {
    background-color: #3a2d7d !important;
   }
   .multi-active > li.is-active:before {
    content: '';
   }
   .multi-steps > li.multi-active:before {
    background-image: url(../img/check-solid-main.svg) !important;
   }
   .small-text {
    font-size: 13px;
    color: #67697c;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
   }
   .slider-container .rangeslider {
    height: 3px;
    margin: 10px 0px;
   }
   .slider-container .rangeslider .rangeslider__fill {
    background: #ec232d;
    background: -moz-linear-gradient(left, #ec232d 0, #2a1541 100%);
    background: -webkit-linear-gradient(left, #ec232d 0, #2a1541 100%);
    background: linear-gradient(to right, #ec232d 0, #2a1541 100%);
   }
   .slider-container .rangeslider-horizontal .rangeslider__handle {
    width: 25px;
    height: 25px;
    margin: 0;
   }
   .slider-container .lrng {
    float: left;
    margin-bottom: 15px;
   }
   .slider-container .rrng {
    float: right;
    margin-bottom: 15px;
   }
   input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
   }
   input[type='number'] {
    -moz-appearance: textfield;
   }
   span.rpan {
    position: absolute;
    bottom: 12px;
    right: 30px;
   }
   .loan-form .row {
    margin: 0;
   }
   .salaried-icon {
    background: url(../img/salaried-icon.png) 0 0 / cover no-repeat;
   }
   .loan-form .icon {
    display: block;
    margin: auto;
    width: 75px;
    height: 75px;
   }
   .loan-form .btn-radio input[type='radio']:checked + label .icon {
    background-position: -75px 0;
   }
   .loan-form .img-radio .btn-radio input[type='radio'] + label {
    text-align: center;
    padding: 5px 10px;
   }
   .loan-form .btn-radio input[type='radio']:checked + label {
    border-color: #3a2d7d;
    background-color: #3a2d7d;
    color: #fff;
   }
   .loan-form .btn-radio input[type='radio'] + label {
    border: 1px solid #d3d7dd;
    min-width: 140px;
    text-align: left;
    text-transform: uppercase;
    padding: 5px 10px 5px 40px;
    line-height: 38px;
    margin: 0 15px 15px 0;
   }
   .btn-checkbox input[type='checkbox'] + label, .btn-radio input[type='radio'] + label {
    position: relative;
    padding: 0 0 0 24px;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 8px;
    font-size: 14px;
    line-height: 1.2;
   }
   .self-employed-icon {
    background: url(../img/self-employed-icon.png) 0 0 / cover no-repeat;
   }
   .price-tag {
    position: relative;
   }
   .price-tag .form-control {
    padding: 0.375rem 1.5rem;
   }
   .loan-form .form-control {
    background-color: transparent;
   }
   .price-tag .lpan {
    left: 10px;
   }
   .price-tag .lpan, .price-tag .rpan {
    position: absolute;
    top: 11px;
    z-index: 2;
   }
   .price-tag .rpan {
    right: 10px;
   }
   .price-tag .lpan, .price-tag .rpan {
    position: absolute;
    top: 11px;
    z-index: 2;
   }
   label {
    font-weight: 400;
   }
   label {
    display: inline-block;
    margin-bottom: 0.5rem;
   }
   .cash-icon {
    background: url(../img/cash-icon.png) 0 0 / cover no-repeat;
   }
   .cheque-icon {
    background: url(../img/cheque-icon.png) 0 0 / cover no-repeat;
   }
   .bank-credit-icon {
    background: url(../img/bank-credit-icon.png) 0 0 / cover no-repeat;
   }
   .loan-form .title-sm {
    color: #3a2d7d;
    font-size: 1.625rem;
    font-weight: bold;
    margin-bottom: 30px;
   }
   .price-tag .lpan {
    left: 10px;
   }
   .price-tag .lpan, .price-tag .rpan {
    position: absolute;
    top: 11px;
    z-index: 2;
   }
   .slider-container .lpan {
    left: 24px;
   }
   .slider-container .lpan, .slider-container .rpan {
    position: absolute;
    top: 120px;
    z-index: 2;
   }
   h4.negative-note {
    font-size: 13px;
   }
   .loan-offer-notes .eligibility-sec {
    background: #3a2d7d;
    color: #fff;
    padding: 15px 25px;
    margin: 0 15px 0 0;
    text-align: center;
    white-space: nowrap;
    font-size: var(--font-size-6);
   }
   .loan-offer-notes .eligibility-sec small {
    display: block;
    font-size: var(--font-size-4);
    color: #ccc;
   }
   .loan-offer-notes .eligibility-sec p {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    font-family: var(--font-bold);
   }
   .loan-offer-notes .eligibility-info {
    color: #ec232d;
    border-left: 5px solid #ec232d;
    background: #ead9e0;
    padding: 15px 25px 15px 40px;
    position: relative;
   }
   .loan-offer-notes .eligibility-info img {
    position: absolute;
    top: 18px;
    left: 15px;
   }
   .loan-offer-notes .eligibility-info p, .loan-offer-notes .eligibility-info-fail p {
    margin: 0;
   }
   .contact-info {
    background: var(--white-background);
    border: 1px solid #e2e2e2;
    padding: 2rem 1rem;
    text-align: center;
    margin-top: 20px;
    min-height: 330px;
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / 10%);
   }
   .loan-eligibility .contact-info {
    min-height: 160px;
   }
   .loan-eligibility .contact-info p {
    margin: 0;
    padding-top: 40px;
    line-height: normal;
    font-weight: 400;
   }
   .customized-loan-offer .form-control {
    border: 1px solid #dce1e5;
   }
   .loan-eligibility .contact-info p:hover {
    color: #ec232d;
   }
   @media only screen and (max-width: 991px) {
    .slider-container .lpan, .slider-container .rpan {
     top: 106px;
    }
   }